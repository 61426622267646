var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "规则说明"
    }
  }, [_c("quill-editor", {
    ref: "myQuillEditor",
    staticClass: "editor",
    attrs: {
      options: _vm.editorOption
    },
    on: {
      blur: function blur($event) {
        return _vm.onEditorBlur($event);
      },
      focus: function focus($event) {
        return _vm.onEditorFocus($event);
      },
      ready: function ready($event) {
        return _vm.onEditorReady($event);
      },
      change: function change($event) {
        return _vm.onEditorChange($event);
      }
    },
    model: {
      value: _vm.productdescribe,
      callback: function callback($$v) {
        _vm.productdescribe = $$v;
      },
      expression: "productdescribe"
    }
  }), _c("form", {
    attrs: {
      action: "",
      method: "post",
      enctype: "multipart/form-data",
      id: "uploadFormMulti"
    }
  }, [_c("input", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: _vm.uniqueId,
      type: "file",
      name: "file",
      multiple: "",
      accept: "image/jpg, image/jpeg, image/png, image/gif"
    },
    on: {
      change: function change($event) {
        return _vm.handleUploadImg("uploadFormMulti");
      }
    }
  })])], 1)], 1), _c("div", {
    staticClass: "rule_btn"
  }, [_c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      background: "#f5f5f5",
      border: "0",
      "margin-right": "26px"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd("back");
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    staticClass: "btn_1",
    staticStyle: {
      color: "#3270F5",
      "margin-right": "26px",
      border: "1px solid #3270F5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.preview();
      }
    }
  }, [_vm._v("预览")]), _c("a-button", {
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")])], 1), _c("div", {
    staticClass: "showdetail"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };